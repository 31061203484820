import { PercentPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { fadeInOut } from '@ih/animations';
import { ConfirmDialogService } from '@ih/confirm';
import { AppConfig } from '@ih/interfaces';
import { ConfigService } from '@ih/services';
import { wrapSvg } from '@ih/utilities';
import { mdiClose, mdiWindowMinimize } from '@mdi/js';
import { OnboardingComponent } from '../onboarding/onboarding.component';
import { OVERLAY_REF, OnboardingPanelService } from './onboarding-panel.service';
import { OnboardingService } from './onboarding.service';
import { OnboardingProgressComponent } from '../onboarding-progress/onboarding-progress.component';

@Component({
  selector: 'ih-onboarding-panel',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    OnboardingComponent,
    OnboardingProgressComponent,
    PercentPipe
  ],
  templateUrl: './onboarding-panel.component.html',
  styleUrl: './onboarding-panel.component.scss',
  animations: [fadeInOut]
})
export class OnboardingPanelComponent {
  private overlayRef = inject(OVERLAY_REF);
  private onboarding = inject(OnboardingService);
  private onboardingPanel = inject(OnboardingPanelService);
  private confirmDialog = inject(ConfirmDialogService);
  private http = inject(HttpClient);
  private config = inject(ConfigService<AppConfig>);

  @HostBinding('ih-onboarding-panel') ihOnboardingPanel = true;
  @HostBinding('@fadeInOut') fadeInOut = true;
  @HostBinding('class.expanded') get expanded() {
    return this.onboarding.expanded();
  }

  percentage = this.onboarding.percentComplete;

  onboardingStatus = this.onboarding.onboarding;
  expandedSignal = this.onboarding.expanded;

  constructor(registry: MatIconRegistry, sanitizer: DomSanitizer) {
    registry.addSvgIconLiteral('close', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiClose)));
    registry.addSvgIconLiteral('window-minimize', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiWindowMinimize)));
  }

  toggleExpanded() {
    this.onboarding.expanded.update((expanded) => !expanded);
  }

  async closeOnboarding(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.onboardingStatus()?.completed) {
      this.onboardingPanel.hide();
      this.overlayRef.dispose();
      this.onboarding.updateOnboardingFlag('hiding', true);
    }
  }
}
