<div class="complete-your-hub-steps">
  <div class="step fx-layout-column">
    <div class="fx-layout-row fx-layout-align-start-center">
      @if (onboardingStatus()?.lookAndFeelCompleted && onboardingStatus()?.homeCustomizationCompleted) {
        <div class="step-number fx-layout-align-center-center"><mat-icon svgIcon="check-bold"></mat-icon></div>
      } @else {
        <div class="step-number fx-layout-align-center-center">1</div>
      }
      <div class="step-title">Design your hub</div>
    </div>
    <div class="step-content fx-layout-column">
      <button mat-flat-button class="step-button" routerLink="/home" (click)="showHomeCustomization()">
        Update homepage
      </button>
      <button mat-flat-button class="step-button" routerLink="/admin/lookAndFeel" (click)="emitAndNavigate()">
        Add colors & logo
      </button>
    </div>
  </div>

  <mat-divider class="step-divider"></mat-divider>

  <div class="step fx-layout-column">
    <div class="fx-layout-row fx-layout-align-start-center">
      @if (onboardingStatus()?.createFirstPostCompleted) {
        <div class="step-number fx-layout-align-center-center"><mat-icon svgIcon="check-bold"></mat-icon></div>
      } @else {
        <div class="step-number fx-layout-align-center-center">2</div>
      }
      <div class="step-title">Create your first post</div>
    </div>
    <div class="step-content fx-layout-column">
      <button mat-flat-button class="step-button" (click)="showDialog()">Compose a post</button>
    </div>
  </div>

  <mat-divider class="step-divider"></mat-divider>

  <div class="step fx-layout-column">
    <div class="fx-layout-row fx-layout-align-start-center">
      @if (onboardingStatus()?.sharingCompleted) {
        <div class="step-number fx-layout-align-center-center"><mat-icon svgIcon="check-bold"></mat-icon></div>
      } @else {
        <div class="step-number fx-layout-align-center-center">3</div>
      }
      <div class="step-title">Share your hub!</div>
    </div>
    <div class="step-content fx-layout-column">
      <div class="share-buttons">
        <!-- Webshare -->
        <button
          mat-icon-button
          class="web-share"
          *ngIf="canWebShare"
          (click)="webShare(); shared()"
          trackClick="Share controls Webshare button clicked"
          [trackProperties]="{ url: this.url }"
          title="Share via link"
        >
          <mat-icon svgIcon="share-variant"></mat-icon>
        </button>

        <!-- Facebook -->
        <button
          mat-icon-button
          class="facebook"
          ihSocialShare="facebook"
          [url]="this.url"
          trackClick="Share controls Facebook share button clicked"
          [trackProperties]="{ url: this.url }"
          title="Share on Facebook"
          (click)="shared()"
        >
          <mat-icon svgIcon="facebook"></mat-icon>
        </button>

        <!-- X-Twitter -->
        <button
          mat-icon-button
          class="twitter"
          ihSocialShare="twitter"
          [url]="this.url"
          trackClick="Share controls Twitter share button clicked"
          [trackProperties]="{ url: this.url }"
          title="Share on Twitter"
          (click)="shared()"
        >
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
              ></path>
            </svg>
          </mat-icon>
        </button>

        <!-- LinkedIn -->
        <button
          mat-icon-button
          class="linkedin"
          ihSocialShare="linkedin"
          [url]="this.url"
          trackClick="Share controls LinkedIn share button clicked"
          [trackProperties]="{ url: this.url }"
          title="Share on LinkedIn"
          (click)="shared()"
        >
          <mat-icon svgIcon="linkedin"></mat-icon>
        </button>

        <!-- Copy Link -->
        <button
          mat-icon-button
          class="link"
          aria-label="Copy link"
          [copy]="this.url"
          trackClick="Share controls Copy link button clicked"
          [trackProperties]="{ url: this.url }"
          title="Copy link to clipboard"
          (click)="shared()"
        >
          <mat-icon svgIcon="link"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
