import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommandService } from '@ih/debug';
import { AppConfig, Onboarding } from '@ih/interfaces';
import { AuthService, ConfigService, EventBusService } from '@ih/services';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private configService = inject(ConfigService<AppConfig>);
  private authService = inject(AuthService);
  private http = inject(HttpClient);
  private eventBus = inject(EventBusService);

  onboarding = toSignal(this.configService.config$.pipe(map((config) => config.onboarding)));
  expanded = signal<boolean>(false);
  percentComplete = computed(() => {
    // percentage is based on how many flags are set to true (excluding the complete flag itself)
    const onboarding = this.onboarding();
    if (!onboarding) {
      return 1;
    }
    const compute = { ...onboarding };
    if ('completed' in compute) {
      delete compute['completed'];
    }
    if ('hiding' in compute) {
      delete compute['hiding'];
    }
    // excluding the hiding and completed flags
    const total = Object.keys(compute).length;
    const complete = Object.values(compute).filter((flag) => flag).length;
    return Math.max((complete / total) * 100, 10);
  });

  currentUser = toSignal(this.authService.currentUser$);
  config = toSignal(this.configService.config$);
  shouldShowOnboarding = computed(() => {
    const user = this.currentUser();
    const config = this.config();
    if (!user) {
      return false;
    }
    if (!config) {
      return false;
    }

    return user.isOwner && (!config.onboarding || !config.onboarding.completed);
  });

  constructor(command: CommandService) {
    command.registerCommand({
      name: 'Onboarding: Reset onboarding',
      executeFactory: () => () => {
        this.configService.updateConfig({
          onboarding: {
            completed: false,
            hiding: false,
            createFirstPostCompleted: false,
            homeCustomizationCompleted: false,
            lookAndFeelCompleted: false,
            sharingCompleted: false
          }
        });
        this.http.put('api/campaign/mine/settings/onboarding', this.onboarding()).subscribe(() => {
          this.configService.syncConfig().subscribe();
        });
        this.expanded.set(false);
      }
    });
  }

  updateOnboardingFlag(flag: keyof Onboarding, value: boolean) {
    const onboarding = this.onboarding();
    if (!onboarding) {
      this.configService.updateConfig({
        onboarding: {
          homeCustomizationCompleted: false,
          lookAndFeelCompleted: false,
          createFirstPostCompleted: false,
          sharingCompleted: false,
          completed: false,
          hiding: false,
          [flag]: value
        }
      });
    } else {
      // if every flag except hidden and completed is set to true, set the completed flag to true
      this.configService.updateConfig({
        onboarding: {
          ...onboarding,
          [flag]: value,
          completed: (Object.keys(onboarding) as (keyof Onboarding)[])
            .filter((key) => key !== 'completed' && key !== 'hiding' && key !== flag)
            .every((key) => onboarding[key])
        }
      });
    }

    this.http.put('api/campaign/mine/settings/onboarding', this.onboarding()).subscribe(() => {
      this.configService.syncConfig().subscribe();
    });
  }
}
