<div class="onboarding-minimized fx-layout-row fx-layout-align-start-center" (click)="showOnboarding()">
  <div class="onboarding-progress-container">
    <div class="progress-ring fx-layout-row fx-layout-align-center-center">
      {{ percentage() / 100 | percent: '1.0-0' }}
    </div>
    <mat-progress-spinner
      #spinner
      class="onboarding-progress-circular"
      strokeWidth="14"
      diameter="64"
      [value]="percentage()"
    ></mat-progress-spinner>
  </div>
  <span class="onboarding-progress-text fx-flex">Personalize your&nbsp;HUB</span>
</div>
