import { AsyncPipe, PercentPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, effect, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmDialogService } from '@ih/confirm';
import { AppConfig } from '@ih/interfaces';
import { ConfigService } from '@ih/services';
import { switchMap } from 'rxjs';
import { OnboardingPanelService } from '../onboarding-panel/onboarding-panel.service';
import { OnboardingService } from '../onboarding-panel/onboarding.service';

@Component({
  selector: 'ih-onboarding-progress',
  standalone: true,
  imports: [MatProgressSpinnerModule, MatIconModule, MatButtonModule, PercentPipe, AsyncPipe],
  templateUrl: './onboarding-progress.component.html',
  styleUrl: './onboarding-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingProgressComponent {
  private onboarding = inject(OnboardingService);
  private onboardingPanel = inject(OnboardingPanelService);
  private confirmDialog = inject(ConfirmDialogService);
  private http = inject(HttpClient);
  private config = inject(ConfigService<AppConfig>);

  @ViewChild('spinner', { read: ElementRef }) spinner!: ElementRef<HTMLElement>;

  dismissable = input(true);

  percentage = this.onboarding.percentComplete;

  constructor() {
    effect(() => {
      if (!this.onboarding.expanded()) {
        requestAnimationFrame(() => {
          this.adjustSpinner();
        });
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.adjustSpinner();
    });
  }

  private adjustSpinner() {
    if (!this.spinner) {
      return;
    }

    const spinnerSvg = this.spinner.nativeElement.querySelector('svg');
    const circle = spinnerSvg!.querySelector('circle');
    if (circle) {
      circle.setAttribute('stroke-linecap', 'round');
    }
  }

  showOnboarding() {
    this.onboarding.updateOnboardingFlag('hiding', false);

    this.onboarding.expanded.set(true);

    this.onboardingPanel.open();
  }

  async closeOnboarding() {
    if (!this.onboarding.onboarding()?.completed) {
      this.onboardingPanel.hide();
      this.onboardingPanel.overlayRef?.dispose();
      this.onboarding.updateOnboardingFlag('hiding', true);
    }
  }
}
