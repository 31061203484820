import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnInit, computed, inject, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { AnimatedCheckComponent } from '@ih/animated-check';
import { EditPageService } from '@ih/content';
import { CopyDirective, SocialShareDirective, TrackClickDirective } from '@ih/directives';
import { AppConfig, Channel } from '@ih/interfaces';
import { ConfigService, LazyInject, ScriptService } from '@ih/services';
import { tweet, wrapSvg } from '@ih/utilities';
import { mdiCheckBold, mdiFacebook, mdiLink, mdiLinkedin, mdiShareVariant, mdiTwitter } from '@mdi/js';
import { BehaviorSubject, take } from 'rxjs';
import { OnboardingService } from '../onboarding-panel/onboarding.service';

@Component({
  selector: 'ih-onboarding',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatStepperModule,

    AnimatedCheckComponent,
    CopyDirective,
    SocialShareDirective,
    TrackClickDirective
  ],
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent implements OnInit {
  private onboarding = inject(OnboardingService);
  private editPage = inject(EditPageService);
  private script = inject(ScriptService);
  private lazyInject = inject(LazyInject);
  private config = inject(ConfigService<AppConfig>);
  private router = inject(Router);

  @HostBinding('class.ih-onboarding') hostClass = true;

  url$ = new BehaviorSubject<string>('');
  channel$ = new BehaviorSubject<Channel>(null as any);
  private configSignal = toSignal(this.config.config$);
  private title = computed(() => this.configSignal()?.businessName || '');

  @Input()
  get url(): string {
    return this.url$.value;
  }

  set url(v: string) {
    this.url$.next(v);
  }

  canWebShare = navigator.share;

  expandToggleRequested = output<void>();
  itemClicked = output<void>();

  onboardingStatus = this.onboarding.onboarding;

  constructor(registry: MatIconRegistry, sanitizer: DomSanitizer) {
    registry.addSvgIconLiteral('share-variant', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiShareVariant)));
    registry.addSvgIconLiteral('facebook', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiFacebook)));
    registry.addSvgIconLiteral('twitter', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiTwitter)));
    registry.addSvgIconLiteral('linkedin', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiLinkedin)));
    registry.addSvgIconLiteral('link', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiLink)));
    registry.addSvgIconLiteral('check-bold', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiCheckBold)));
  }

  ngOnInit(): void {
    this.script.loadSocial().subscribe();
  }

  facebookShare(): void {
    window.FB.ui(
      {
        method: 'share',
        href: this.url,
        display: 'iframe'
      },
      (response: any) => {
        // Debug response (optional)
        console.log(response);
      }
    );
  }

  twitterShare(): void {
    tweet(
      'https://twitter.com/intent/tweet?url=' +
        window.encodeURIComponent(this.url) +
        '&text=' +
        window.encodeURIComponent(this.title()) +
        '%0A'
    );
  }

  webShare(): void {
    window.navigator
      .share({
        title: this.title(),
        url: this.url
      })
      .then(() => console.log('Success sharing'))
      .catch((error) => console.log('Error sharing', error));
  }

  shared(): void {
    // update onboarding status
    this.onboarding.updateOnboardingFlag('sharingCompleted', true);
  }

  async showHomeCustomization(): Promise<Promise<void>> {
    this.editPage.setEditing(true);
    this.expandToggleRequested.emit();
    // update onboarding status
    this.onboarding.updateOnboardingFlag('homeCustomizationCompleted', true);
  }

  emitAndNavigate() {
    this.itemClicked.emit();
    this.expandToggleRequested.emit();
    // update onboarding status
    this.onboarding.updateOnboardingFlag('lookAndFeelCompleted', true);
  }

  async showDialog(): Promise<void> {
    await this.router.navigate(['/home']);

    const postEditor = await this.lazyInject.get(() => import('@ih/post-editor').then((m) => m.PostEditorService));
    this.expandToggleRequested.emit();
    this.config.config$.pipe(take(1)).subscribe((config) => {
      postEditor
        .open({
          channels: [config.homeChannel],
          instantPublish: true
        })
        .then((dialogResult) => {
          dialogResult.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              // update step
              this.onboarding.updateOnboardingFlag('createFirstPostCompleted', true);
            }
          });
        });
    });
  }
}
