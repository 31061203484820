@if (expandedSignal()) {
  <div @fadeInOut class="onboarding-list-container">
    <div class="onboarding-header fx-layout-row fx-layout-align-start-center">
      <div class="onboarding-title fx-flex">Personalize your HUB</div>
      <button mat-icon-button class="onboarding-minimize-btn" (click)="toggleExpanded()">
        <mat-icon svgIcon="window-minimize"></mat-icon>
      </button>
      <button mat-icon-button class="onboarding-hide-btn" (click)="closeOnboarding($event)">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </div>

  <ih-onboarding (expandToggleRequested)="toggleExpanded()"></ih-onboarding>
} @else {
  <ih-onboarding-progress @fadeInOut></ih-onboarding-progress>
}
