import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, effect, inject } from '@angular/core';
import { EventBusService } from '@ih/services';
import { OnboardingPanelComponent } from './onboarding-panel.component';
import { OnboardingService } from './onboarding.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingPanelService {
  private overlay = inject(Overlay);
  private injector = inject(Injector);
  private onboarding = inject(OnboardingService);
  private eventBus = inject(EventBusService);

  private overlayConfig: OverlayConfig = {
    panelClass: 'ih-onboarding-panel'
  };

  overlayRef?: OverlayRef;
  visible = false;

  constructor() {
    effect(
      () => {
        const onboarding = this.onboarding.onboarding();
        if (!onboarding) {
          return;
        }

        if (onboarding.completed) {
          this.hide();
        }
      },
      {
        allowSignalWrites: true
      }
    );

    this.eventBus.on('complete-profile-dialog:done', () => {
      // if this is the owner and onboarding is not completed then open the onboarding overlay
      if (this.onboarding.shouldShowOnboarding()) {
        console.debug('Opening onboarding overlay');

        if (!this.visible && !this.onboarding.onboarding()?.hiding) {
          this.open();
        }
      }
    });
  }

  open(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    this.overlayRef = this.overlay.create(this.overlayConfig);
    if (this.onboarding.onboarding()?.hiding) {
      return this.overlayRef;
    }

    const injector = Injector.create({
      providers: [{ provide: OVERLAY_REF, useValue: this.overlayRef }],
      parent: this.injector
    });

    const portal = new ComponentPortal(OnboardingPanelComponent, null, injector);
    this.overlayRef.attach(portal);

    this.visible = true;

    return this.overlayRef;
  }

  hide() {
    if (this.overlayRef) {
      this.onboarding.expanded.set(false);
      this.visible = false;
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }
}

export const OVERLAY_REF = new InjectionToken<OverlayRef>('OverlayRef');
